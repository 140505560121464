.App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.App-header {
  margin-top: 5vh;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.center {
  margin: 0 auto;
}

.initial-date {
  padding-top: 1%;
}

.infobox {
  padding-top: 1%;
  width: 50%;
  margin: 0 auto;
}

.MuiAlert-message {
  margin: 0 auto !important;
}